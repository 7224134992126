<template>
  <LiefengContent>
    <template #title>议事投票名单</template>
    <template #toolsbarRight>
      <Button
        @click="exportExcel"
        type="primary"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
      <Button type="error" icon="ios-arrow-back" @click="$router.push({path: '/discussionmanage',query: {businessId: $route.query.parentBusinessId,menuId: $route.query.menuId,startend: $route.query.startend}})">返回</Button>
    </template>
    <template #contentArea>
         <LiefengTable
          :tableData="tableData"
          :talbeColumns="talbeColumns"
          :loading="loading"
          :pageSize="pageSize"
          :pagesizeOpts="[20, 30, 50, 100, 200]"
          :total="total"
          :curPage="page"
          :fixTable="true"
          @hadlePageSize="hadlePageSize"
        ></LiefengTable>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/discussionvotinglist')
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
    tableData: [],
      talbeColumns: [
        {
          title: "头像",
          key: 'portraitUrl',
          width: 160,
          align: 'center',
          render: (h,params) => {
            return h('img',{
                attrs: {
                  src: params.row.portraitUrl
                },
                style: {
                  width: '100px'
                }
              })
          }
        },
        {
          title: "姓名",
          key: "name",
          width: 160,
          align: 'center'
        },
        {
          title: "昵称",
          key: "nickName",
          width: 250,
          align: 'center'
        },
        {
          title: "年龄",
          key: "age",
          width: 100,
          align: 'center'
        },
        {
          title: "性别",
          key: "sex",
          width: 100,
          align: 'center'
        },
        {
          title: "所在详细地址",
          key: "addr",
          minWidth: 200,
          align: 'center'
        },
        {
          title: "投票时间",
          key: "voteTime",
          width: 200,
          align: 'center'
        },
        {
          title: "投票项",
          key: "voteItem",
          minWidth: 200,
          align: 'center'
        },

      ],
      loading: false,
      pageSize: 20,
      total: 0,
      page: 1,
    };
  },
  methods: {
    //   导出
    exportExcel() {
        this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "姓名",
            "昵称",
            "年龄",
            "性别",
            "所在详细地址",
            "投票时间",
            "投票项"
          ];
          let filterVal = [
            "name",
            "nickName",
            "age",
            "sex",
            "addr",
            "voteTime",
            "voteItem"
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "议事投票名单列表"
          );
        },
      });
    },
      // 投票名单
    hadlePageSize(obj) {
      this.loading = true;
      this.$get('/sendmsg/pc/chat/discussBusiness/queryVoteRecordPage',{
        businessId: this.$route.query.businessId,
        page: obj.page,
        pageSize: obj.pageSize
      }).then(res => {
        if(res.code == 200 && res.dataList) {
           this.tableData = res.dataList.map(item => {
               let voteItem = [];
               if(item.voteItemList) {
                   item.voteItemList.map(subItem => {
                       voteItem.push(subItem.title);
                   })
               }
               return {
                portraitUrl: item.portraitUrl,
                name: item.name,
                nickName: item.nickName,
                age: item.age,
                sex: item.sex == '1' ? '男' : item.sex == '2' ? '女' : item.sex == '0' ? '未知' : '',
                addr: item.addr,
                voteTime: item.voteTime ? this.$core.formatDate(new Date(item.voteTime),"yyyy-MM-dd hh:mm:ss") : '',
                voteItem: voteItem.join(',')
               }
           });
            this.total = res.maxCount;
            this.pageSize = res.pageSize;
            this.page = res.currentPage;
            this.loading = false;
        }else {
           this.loading = false;
            this.$Message.error({
              background: true,
              content: res.desc,
            });
        }
      }).catch(err => {
         this.loading = false;
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
      })
    },
  },
  components: {
    LiefengTable,
    LiefengContent,
  },
  created() {
      this.hadlePageSize({
          page: this.page,
          pageSize: this.pageSize
      })
  }
};
</script>
    
<style scoped lang='less'>
</style>